import React from 'react'
import { Link } from 'react-router-dom'

function Connect() {
    return (
        <div>
            <div className="p-4">
                <Link to='/programs' className="text-muted-foreground mb-4 inline-block">← Back</Link>
                <div className="flex flex-col lg:flex-row gap-8 p-10 px-20">
                    <div className="flex-1">
                        <h1 className="text-4xl font-bold">
                            Senior
                            <br />
                            Connect
                        </h1>
                        <ul className="mt-4 space-y-2">
                            <li className="flex items-center">
                                <span className="text-green-500">✔</span>
                                <span className="ml-2">Connect 'Directly' with your future seniors.</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✔</span>
                                <span className="ml-2">Get The Real Placement Insights</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✔</span>
                                <span className="ml-2">Get Real Time Day to Day College Insights</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✔</span>
                                <span className="ml-2">Clear your smallest doubts.</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✔</span>
                                <span className="ml-2">Get Clarity on your college choices.</span>
                            </li>
                        </ul>
                        <div className='flex flex-row mt-4'>
                        <p className=" text-lg">
                            COST FOR SINGLE SESSION: <span className="font-bold">₹499</span>
                        </p>

                        <button className='border-green-500 border-2 text-green-500 md:bg-green-500 hover:bg-green-600 hover:text-white md:text-white p-1 md:py-2 md:px-4 rounded-full text-xs ml-3'>
                           <Link to='https://pages.razorpay.com/stores/kycStore'>Buy Now</Link>
                        </button>
                        </div>
                        
                    </div>

                    <div className="flex-1 bg-card p-6 rounded-lg shadow-lg">
                        <form className="space-y-4">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-foreground">Name</label>
                                <input type="text" id="name" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Enter your full name" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-foreground">Gender</label>
                                <div className="mt-1 flex items-center space-x-4">
                                    <label className="flex items-center">
                                        <input type="radio" name="gender" value="male" className="form-radio" />
                                        <span className="ml-2">Male</span>
                                    </label>
                                    <label className="flex items-center">
                                        <input type="radio" name="gender" value="female" className="form-radio" />
                                        <span className="ml-2">Female</span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="whatsapp" className="block text-sm font-medium text-foreground">Whatsapp no</label>
                                <input type="text" id="whatsapp" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Enter your Whatsapp no" />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-foreground">Email id</label>
                                <input type="email" id="email" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Enter your valid Email id" />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-foreground">Password</label>
                                <input type="password" id="password" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Enter your password" />
                            </div>
                            <div>
                                <label htmlFor="jee-rank" className="block text-sm font-medium text-foreground">JEE Rank</label>
                                <input type="text" id="jee-rank" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Enter your valid JEE rank" />
                            </div>
                            <div>
                                <label htmlFor="query" className="block text-sm font-medium text-foreground">Query</label>
                                <input type="text" id="query" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Ask your query" />
                            </div>
                            <div>
                                <label htmlFor="preferred-colleges" className="block text-sm font-medium text-foreground">Preferred Colleges</label>
                                <input type="text" id="preferred-colleges" className="mt-1 block w-full border border-input rounded-md p-2" placeholder="Preferred colleges" />
                            </div>
                            <button type="submit" className="w-full bg-primary text-primary-foreground py-2 rounded-md hover:bg-primary/80">Go next →</button>
                        </form>
                    </div>
                </div>
                <p className="mt-8 text-center text-muted-foreground text-2xl">
                    COLLEGES <span className="text-primary">SHAPE YOUR LIFE AHEAD</span>, REMEMBER TO GIVE YOUR LIFE THE SHAPE YOU LOVE BY CHOOSING THE <span className="text-primary">BEST COLLEGE</span> FOR YOU WITH <span className="text-primary">KYC</span>.
                </p>
                <h2 className="m-8 text-center text-6xl font-semibold">Testimonials</h2>
                <div className="mt-4 flex flex-wrap justify-center gap-10 w-2/3 m-auto">
                    <div className="bg-card p-4 rounded-lg shadow-lg w-80">
                        <div className="flex items-center space-x-4">
                            <img src="https://placehold.co/40x40" alt="User profile picture" className="w-10 h-10 rounded-full" />
                            <div>
                                <h3 className="font-semibold">Abhinav</h3>
                                <p className="text-sm text-muted-foreground">⭐⭐⭐⭐⭐</p>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-muted-foreground">"I struggled with my assignments until I found this platform. The mentors provided excellent guidance, and I saw a significant improvement in my grades!"</p>
                        <p className="mt-2 text-xs text-muted-foreground">user: 2023-04-01</p>
                    </div>
                    <div className="bg-card p-4 rounded-lg shadow-lg w-80">
                        <div className="flex items-center space-x-4">
                            <img src="https://placehold.co/40x40" alt="User profile picture" className="w-10 h-10 rounded-full" />
                            <div>
                                <h3 className="font-semibold">Aditya</h3>
                                <p className="text-sm text-muted-foreground">⭐⭐⭐⭐⭐</p>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-muted-foreground">"No words to explain, I have got more than I have expected. I have got the best mentor who has helped me in every aspect of my preparation."</p>
                        <p className="mt-2 text-xs text-muted-foreground">user: 2023-03-15</p>
                    </div>
                    <div className="bg-card p-4 rounded-lg shadow-lg w-80">
                        <div className="flex items-center space-x-4">
                            <img src="https://placehold.co/40x40" alt="User profile picture" className="w-10 h-10 rounded-full" />
                            <div>
                                <h3 className="font-semibold">Divya</h3>
                                <p className="text-sm text-muted-foreground">⭐⭐⭐⭐⭐</p>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-muted-foreground">"I struggled with my assignments until I found this platform. The mentors provided excellent guidance, and I saw a significant improvement in my grades!"</p>
                        <p className="mt-2 text-xs text-muted-foreground">user: 2023-04-01</p>
                    </div>
                    <div className="bg-card p-4 rounded-lg shadow-lg w-80">
                        <div className="flex items-center space-x-4">
                            <img src="https://placehold.co/40x40" alt="User profile picture" className="w-10 h-10 rounded-full" />
                            <div>
                                <h3 className="font-semibold">Khushi</h3>
                                <p className="text-sm text-muted-foreground">⭐⭐⭐⭐⭐</p>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-muted-foreground">"I struggled with my assignments until I found this platform. The mentors provided excellent guidance, and I saw a significant improvement in my grades!"</p>
                        <p className="mt-2 text-xs text-muted-foreground">user: 2023-04-01</p>
                    </div>
                    <div className="bg-card p-4 rounded-lg shadow-lg w-80">
                        <div className="flex items-center space-x-4">
                            <img src="https://placehold.co/40x40" alt="User profile picture" className="w-10 h-10 rounded-full" />
                            <div>
                                <h3 className="font-semibold">Vipul</h3>
                                <p className="text-sm text-muted-foreground">⭐⭐⭐⭐⭐</p>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-muted-foreground">"I struggled with my assignments until I found this platform. The mentors provided excellent guidance, and I saw a significant improvement in my grades!"</p>
                        <p className="mt-2 text-xs text-muted-foreground">user: 2023-04-01</p>
                    </div>
                </div>
                <div className="mt-20 mb-10 flex justify-around text-center">
                    <div>
                        <p className="text-4xl font-bold text-primary">250+</p>
                        <p className="text-muted-foreground">colleges</p>
                    </div>
                    <div>
                        <p className="text-4xl font-bold text-primary">3500+</p>
                        <p className="text-muted-foreground">students</p>
                    </div>
                    <div>
                        <p className="text-4xl font-bold text-primary">25+</p>
                        <p className="text-muted-foreground">mentors</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Connect

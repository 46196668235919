import mentor from "../../assets/image/mentor.jpeg";
import { MessageCircleMore } from "lucide-react";

const ViewMentors = () => {
  return (
    
         <div className="flex-col  bg-white rounded-md p-4 w-full mt-4">
              <div className="flex justify-between items-center px-1 m-1">
                <img className="rounded-full w-10 h-10" src={mentor} alt="" />
                <div className="flex-col text-sm">
                  <h4>Ana J</h4>
                  <p>B.Tech CSE (2016-2020)</p>
                </div>
                <MessageCircleMore />
              </div>
              
            </div>
    
  )
}

export default ViewMentors
import React from 'react'

const TextGreen = ({text}) => {
  return (
    <div>
        <p className='text-customGreen px-3'>{text}</p>
    </div>
  )
}

export default TextGreen
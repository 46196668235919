

import Courses from './Courses'
const ListedCourses = ()=> {
  return (
    
    <div className="w-full my-3">
        <h3 className="text-3sx font-bold py-4">Undergraduate Programs (66+)</h3>
        <div className="flex flex-row gap-8 w-full">
        <Courses />
        <Courses />
        </div>
        <hr className="my-4 border-gray-300" />
    </div>
    
  )
}

export default ListedCourses
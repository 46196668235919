import React from 'react'
import Image from '../assets/image/mentor.jpeg'
import Check from '../assets/image/check.png'
import MultiPageForm from './Form/MultiPageForm'
import { Link } from 'react-router-dom'

function Mentor() {
  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-zinc-100">
        <div className="bg-white shadow-lg rounded-lg flex overflow-hidden max-w-4xl w-full">
          <div className="w-full md:w-1/2 p-8">
            <Link to="/" className="text-zinc-500 dark:text-black-400 mb-4 inline-block">&lt; Back</Link>
            <h2 className="text-2xl font-semibold text-zinc-800 dark:text-black mb-2">Become a <span className="text-green-500">MENTOR</span></h2>
            <p className="text-zinc-600 dark:text-black-300 mb-8">Let's get you started, it's quick and easy</p>
            
            <MultiPageForm/>

          </div>
          <div className="w-full md:w-1/2 hidden md:block relative">
            <img className=" w-full h-full object-cover" src={Image} alt="Mentorship Benefits" crossorigin="anonymous" />
              <div className="font-bold absolute top-0 left-0 p-8">
                <ul className="text-black space-y-4">
                  <li className="flex items-center">
                    <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2" crossorigin="anonymous" />
                      Get paid for every session you take
                  </li>
                  <li className="flex items-center">
                    <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2" crossorigin="anonymous" />
                      Get recognition in your college
                  </li>
                  <li className="flex items-center">
                    <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2" crossorigin="anonymous" />
                      Chance to Win additional prizes
                  </li>
                  <li className="flex items-center">
                    <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2" crossorigin="anonymous" />
                      Chance to win the Best Senior Award
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mentor
import { ArrowDownToLine } from 'lucide-react';

const Courses = () => {
  return (
    <div className="rounded-lg w-full bg-white overflow-hidden shadow-md my-4 md:my-0">
      <h3 className="text-base bg-green-400 p-2">
        B.Tech
      </h3>
      <ul className="list-none w-full bg-white">
        <li className="flex flex-row justify-between items-center p-2 border-b last:border-b-0">
          <span className="text-sm md:text-base">Bioengineering</span>
          <ArrowDownToLine className="w-5 h-5 md:w-6 md:h-6" />
        </li>
        <li className="flex flex-row justify-between items-center p-2 border-b last:border-b-0">
          <span className="text-sm md:text-base">Mechanical Engineering</span>
          <ArrowDownToLine className="w-5 h-5 md:w-6 md:h-6" />
        </li>
        <li className="flex flex-row justify-between items-center p-2 border-b last:border-b-0">
          <span className="text-sm md:text-base">Electronics Engineering</span>
          <ArrowDownToLine className="w-5 h-5 md:w-6 md:h-6" />
        </li>
        <li className="flex flex-row justify-between items-center p-2 last:border-b-0">
          <span className="text-sm md:text-base">Information Technology</span>
          <ArrowDownToLine className="w-5 h-5 md:w-6 md:h-6" />
        </li>
      </ul>
    </div>
  );
}

export default Courses;

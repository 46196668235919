const RankingCard = ({ title, children }) => (
    <div className="bg-black text-white p-4 sm:p-6 rounded-lg shadow-lg h-full flex flex-col">
      <h3 className="text-green-400 font-semibold mb-2 text-lg">{title}</h3>
      <div className="flex-grow">{children}</div>
    </div>
  );
  
  const CollegeRanking = () => {
    return (
      <div className="max-w-4xl py-4 md:mx-4 md:p-4">
        <h2 className="text-3xl font-bold mb-6">College Rankings</h2>
        <div className="grid grid-cols-2 gap-4 sm:gap-6">
          <RankingCard title="NIRF">
            <ul className="space-y-1 sm:space-y-2 text-sm sm:text-base">
              <li>Overall Rank- 17 (2023)</li>
              <li>Engineering Rank- 11 (2023)</li>
              <li>Research Rank- 11 (2023)</li>
              <li>Universities Rank- 8 (2023)</li>
            </ul>
          </RankingCard>
  
          <RankingCard title="NAAC Accreditation">
            <p className="text-sm sm:text-base">
              VIT is accredited with an 'A+' grade by NAAC (National Assessment and
              Accreditation Council)
            </p>
          </RankingCard>
  
          <RankingCard title="Other Rankings">
            <p className="text-sm sm:text-base">
              VIT has also been recognized as an Institution of Eminence by the
              Indian Government and is ranked internationally by QS World
              University Rankings and ARWU (Shanghai Ranking).
            </p>
          </RankingCard>
        </div>
      </div>
    );
  };
  
  export default CollegeRanking;
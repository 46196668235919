
const DressCode = ({para, photo1, photo2}) => {
  return (
    <div className="">
        <p className="max-w-[700px] py-4 font-semibold">{para}</p>
        <div className="flex flex-row max-w-[700px]">
            <img className="w-1/2 md:w-[450px] h-[190px] object-cover rounded-2xl " src={photo1} alt="sport" />
            <img className="w-1/2 md:w-[175px] h-[190px] object-cover rounded-2xl px-2" src={photo2} alt="sport" />
    </div>
    </div>
  )
}

export default DressCode

import React from 'react'
import { Link } from 'react-router-dom'

function ProfileEdit() {
    return (
        <div>
            <div className="min-h-screen bg-zinc-100  flex">
                <div className="w-1/5 py-4 flex flex-col justify-between" style={{ backgroundColor: '#001E2B' }}>
                    <div>
                        <ul className="space-y-4 text-white flex flex-col">
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/allotted'>Moderator Allotted</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/scheduled'>Scheduled Sessions</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/saved'>Saved Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/predicted'>Predicted Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/choice'>Choice Filling</Link>
                        </ul>
                    </div>
                    <button className="text-white mt-8">↩ Log Out</button>
                </div>
                <div className="w-4/5 px-4 py-3">
                    <div className="flex justify-between items-center mb-5 bg-white py-3 px-2 rounded-xl">
                        <Link to='/studentdash' className="text-2xl font-bold">Dashboard</Link>
                        <div className="relative">
                            <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 rounded-full bg-white  border border-zinc-300  focus:outline-none" />

                        </div>
                    </div>
                    <div className=" p-6 bg-card text-card-foreground rounded-lg">
                        <h2 className="text-2xl font-semibold mb-2">Edit Profile</h2>
                        <p className="text-muted-foreground mb-6">You can change your details here</p>
                        <div className="flex items-center mb-6">
                            <div className="relative">
                                <img src="https://placehold.co/100x100" alt="Profile Picture" className="w-24 h-24 rounded-full border-2 border-primary" />
                                <button className="absolute bottom-0 right-0 bg-accent text-accent-foreground p-2 rounded-full">
                                    <img undefinedhidden="true" alt="camera-icon" src="https://openui.fly.dev/openui/24x24.svg?text=📷" />
                                </button>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="flex items-center">
                                <label className="w-40 text-right pr-4">Full Name</label>
                                <input type="text"  className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Father's Name</label>
                                <input type="text"  className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Email Id</label>
                                <input type="email" className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Phone</label>
                                <input type="text"  className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Jee Percentile</label>
                                <input type="text"  className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Rank</label>
                                <input type="text" className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Category</label>
                                <input type="text" className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                            <div className="flex items-center">
                                <label className="w-40  text-right pr-4">Home State</label>
                                <input type="text"  className="flex-1 p-2 border border-border rounded-md" />
                                <button className="ml-2 text-primary">
                                    {/* <img undefinedhidden="true" alt="edit-icon" src="https://openui.fly.dev/openui/24x24.svg?text=✏️" /> */}
                                </button>
                            </div>
                        </div>
                        <div className="mt-6 text-right">
                            <button className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-primary/80">Save the Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileEdit
import React from 'react'
import { Link } from 'react-router-dom'

function ChoiceFilling() {
    return (
        <div>
            <div className="min-h-screen bg-zinc-100  flex">
                <div className="w-1/5 py-4 flex flex-col justify-between" style={{ backgroundColor: '#001E2B' }}>
                    <div>
                        <ul className="space-y-4 text-white flex flex-col">
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/allotted'>Moderator Allotted</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/scheduled'>Scheduled Sessions</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/saved'>Saved Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/predicted'>Predicted Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/choice'>Choice Filling</Link>
                        </ul>
                    </div>
                    <button className="text-white mt-8">↩ Log Out</button>
                </div>
                <div className="w-4/5 px-4 py-3">
                    <div className="flex justify-between items-center mb-5 bg-white py-3 px-2 rounded-xl">
                        <Link to='/studentdash' className="text-2xl font-bold">Dashboard</Link>
                        <div className="relative">
                            <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 rounded-full bg-white  border border-zinc-300  focus:outline-none" />

                        </div>
                    </div>
                    <div className='flex gap-8'>
                        <div className="mb-8 w-2/3 h-fit px-5 bg-white py-3 rounded-xl">
                            <div className="p-6 bg-white rounded-lg">
                                <h1 className="text-2xl font-bold text-primary mb-4">Choice Filling Stimulation</h1>
                                <button className="bg-green-500 text-white py-2 px-4 rounded mb-4">Available Choices</button>
                                <div className="overflow-x-auto " style={{height:'40vh'}}>
                                    <table className="min-w-full bg-white border border-zinc-200 rounded-lg">
                                        <thead>
                                            <tr>
                                                <th className="py-2 px-4 border-b">College Name</th>
                                                <th className="py-2 px-4 border-b">Course</th>
                                                <th className="py-2 px-4 border-b"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex justify-end mt-4">
                                    <button className="bg-green-500 text-white py-2 px-4 rounded">Next</button>
                                </div>
                            </div> <hr className='my-5' />
                            <div className="p-6 bg-white rounded-lg ">
                                <button className="bg-green-500 text-white py-2 px-4 rounded mb-4">Final Choices</button>
                                <div className="overflow-x-auto " style={{height:'40vh'}}>
                                    <table className="min-w-full bg-white border border-zinc-200 rounded-lg">
                                        <thead>
                                            <tr>
                                                <th className="py-2 px-4 border-b">College Name</th>
                                                <th className="py-2 px-4 border-b">Course</th>
                                                <th className="py-2 px-4 border-b"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-4 border-b">ABESIT, Ghaziabad</td>
                                                <td className="py-2 px-4 border-b">Btech(Computer Science)</td>
                                                <td className="py-2 px-4 border-b">
                                                    {/* <button className="bg-green-500 text-white py-1 px-3 rounded">Add</button> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex justify-between mt-4">
                                    <button className="bg-green-500 text-white py-2 px-4 rounded">Download</button>
                                    <button className="bg-green-500 text-white py-2 px-4 rounded">New List</button>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div style={{ width: '30rem' }} className="max-w-sm mx-auto bg-card text-card-foreground p-6 rounded-lg shadow-lg bg-white" >
                                <div className="flex flex-col" >
                                    <img src="https://placehold.co/80x80" className='w-1/2 m-auto mb-10 rounded-full' alt="" />
                                    <div className="flex flex-col gap-7">
                                        <p className="font-semibold">Name - <span className="font-normal">Aditya Pandey</span></p>
                                        <p className="font-semibold">College - <span className="font-normal">ABESEC</span></p>
                                        <p className="font-semibold">Email - <span className="font-normal">abc@gmail.com</span></p>
                                        <p className="font-semibold">Phone - <span className="font-normal">98XXXXXXXX</span></p>
                                        <p className="font-semibold">Branch - <span className="font-normal">Computer Science</span></p>
                                        <p className="font-semibold">Current Year - <span className="font-normal">2</span></p>
                                        <p className="font-semibold">Session Counts - <span className="font-normal">10</span></p>
                                        <p className="font-semibold">Ratings -
                                            <span className="font-normal text-green-500">
                                                ★★★
                                            </span>
                                        </p>
                                    </div>
                                    <Link to='/studentdash/profile' className="text-white bg-green-500 mt-4 bg-primary text-primary-foreground py-2 px-4 rounded-lg hover:bg-primary/80">Edit Profile</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoiceFilling
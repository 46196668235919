import React from 'react'
import { Link } from 'react-router-dom'

function ScheduledSessions() {
    return (
        <div>
            <div className="min-h-screen bg-zinc-100  flex">
                <div className="w-1/5 py-4 flex flex-col justify-between" style={{ backgroundColor: '#001E2B' }}>
                    <div>
                        {/* <img src="https://placehold.co/100x100" alt="Logo" className="mb-8" crossorigin="anonymous" /> */}
                        <ul className="space-y-4 text-white flex flex-col">
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/mentordash/sessionrequest'>Session Requests</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/mentordash/scheduledsessions'>Scheduled Sessions</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/mentordash/sessionhistory'>Sessions History</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/mentordash/paymenthistory'>Payment History</Link>
                        </ul>
                    </div>
                    <button className="text-white mt-8">↩ Log Out</button>
                </div>
                <div className="w-4/5 px-4 py-3">
                    <div className="flex justify-between items-center mb-5 bg-white py-3 px-2 rounded-xl">
                        <Link to='/mentordash' className="text-2xl font-bold">Dashboard</Link>
                        <div className="relative">
                            <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 rounded-full bg-white  border border-zinc-300  focus:outline-none" />

                        </div>
                    </div>
                    <div className='flex gap-8 w-full'>
                        <div className='mb-8 w-2/3 h-fit px-5 bg-white py-3 rounded-xl'>

                            <div className="p-6">
                                <h2 className="text-2xl font-bold mb-4">Upcoming Scheduled Sessions</h2>
                                <div className="border rounded-lg p-4 mb-4">
                                    <h3 className="text-lg font-bold text-primary mb-2">Stream or Branch Selection</h3>
                                    <p className="text-muted-foreground">
                                        <strong>Major Doubt:</strong> CSE v/s CS v/s CSAI
                                    </p>
                                    <p className="text-muted-foreground">
                                        <strong>Moderator:</strong> Yash Gaur
                                    </p>
                                    <p className="text-muted-foreground">
                                        <strong>Students:</strong> Sahil, Sarthak, Akshay, Kholi
                                    </p>
                                    <p className="text-muted-foreground">
                                        <strong>Date and Time:</strong> 20 May 2024 and 8:00 AM
                                    </p>
                                    <button className="bg-green-500 text-white mt-4 bg-accent text-accent-foreground py-2 px-4 rounded-lg hover:bg-accent/80">Start the Session</button>
                                </div>
                                <div className="border-2 border-primary rounded-lg p-4 relative">
                                    <h3 className="text-lg font-bold text-primary mb-2">Stream or Branch Selection</h3>
                                    <p className="text-muted-foreground">
                                        <strong>Major Doubt:</strong> CSE v/s CS v/s CSAI
                                    </p>
                                    <p className="text-muted-foreground">
                                        <strong>Moderator:</strong> Yash Gaur
                                    </p>
                                    <p className="text-muted-foreground">
                                        <strong>Students:</strong> Sahil, Sarthak, Akshay, Kholi
                                    </p>
                                    <p className="text-muted-foreground">
                                        <strong>Date and Time:</strong> 20 May 2024 and 8:00 AM
                                    </p>
                                    <button className="mt-4 bg-green-500 text-white bg-accent text-accent-foreground py-2 px-4 rounded-lg hover:bg-accent/80 mr-2">Start the Session</button>
                                    <button className="mt-4 bg-primary bg-red-500 text-white text-primary-foreground py-2 px-4 rounded-lg hover:bg-primary/80">Chat with Moderator</button>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div style={{ width: '30rem' }} className="max-w-sm mx-auto bg-card text-card-foreground p-6 rounded-lg shadow-lg bg-white" >
                                <div className="flex flex-col" >
                                    <img src="https://placehold.co/80x80" className='w-1/2 m-auto mb-10 rounded-full' alt="" />
                                    <div className="flex flex-col gap-7">
                                        <p className="font-semibold">Name - <span className="font-normal">Aditya Pandey</span></p>
                                        <p className="font-semibold">College - <span className="font-normal">ABESEC</span></p>
                                        <p className="font-semibold">Email - <span className="font-normal">abc@gmail.com</span></p>
                                        <p className="font-semibold">Phone - <span className="font-normal">98XXXXXXXX</span></p>
                                        <p className="font-semibold">Branch - <span className="font-normal">Computer Science</span></p>
                                        <p className="font-semibold">Current Year - <span className="font-normal">2</span></p>
                                        <p className="font-semibold">Session Counts - <span className="font-normal">10</span></p>
                                        <p className="font-semibold">Ratings -
                                            <span className="font-normal text-green-500">
                                                ★★★
                                            </span>
                                        </p>
                                    </div>
                                    <button className="text-white bg-green-500 mt-4 bg-primary text-primary-foreground py-2 px-4 rounded-lg hover:bg-primary/80">Edit Profile</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduledSessions
// src/components/PersonalInfo.js
import React, { useState } from 'react';
import Check from '../../assets/image/check.png'
import { useRef } from 'react';

const PersonalInfo = ({ formData, setFormData, nextStep }) => {
    const { Name, CollegeName, CollegeCity, Course, Branch } = formData;
    const [check, setCheck] = useState(false);
    const dialogRef = useRef(null);

    function Change() {
        setCheck(!check)
    }
    function Close() {
        if (check) {
            dialogRef.current?.close();
        }
    }

    return (
        <>
            <dialog ref={dialogRef} open style={{ zIndex: 1 }} className='p-7 rounded-lg shadow-xl shadow-zinc-600'>
                <h1 style={{ fontSize: '30px' }}>Eligibility Criteria</h1>
                <hr />
                <div className='p-4'>
                    <div>
                        <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2 inline" crossorigin="anonymous" />
                        <p className='inline'>2nd , 3rd , or 4th B.tech students can apply</p>
                    </div>
                    <div>
                        <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2 inline" crossorigin="anonymous" />
                        <p className='inline'>Good communication skills</p>
                    </div>
                    <div>
                        <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2 inline" crossorigin="anonymous" />
                        <p className='inline'>Applicant should have complete and clear idea about his college</p>
                    </div>
                    <div>
                        <img undefinedhidden="true" alt="check" src={Check} className="w-6 h-6 mr-2 inline" crossorigin="anonymous" />
                        <p className='inline'>Dedicate 1 or 2 hours on daily basis</p>
                    </div>

                    <div className='my-3'>
                        <input type="checkbox" name="yes" id="yes" onChange={Change}/>
                        <label htmlFor="check" className='mx-2'>I have read all the information carefully</label>
                    </div>

                    <center><button onClick={Close} className='rounded-lg px-10 py-2 bg-green-500 font-bold text-white'>I Agree</button></center>
                </div>

            </dialog>
            <div className=" rounded w-full">
                <div style={{ height: '30rem' }}>
                    <h2 className="text-2xl mb-4">Personal Information</h2>
                    <div className="mb-4 w-full">
                        <label className="block text-gray-700">Name:</label>
                        <input
                            required
                            type="text"
                            value={Name}
                            onChange={(e) => setFormData({ ...formData, Name: e.target.value })}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">College Name:</label>
                        <input
                            required
                            type="text"
                            value={CollegeName}
                            onChange={(e) => setFormData({ ...formData, CollegeName: e.target.value })}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">College City:</label>
                        <input
                            required
                            type="text"
                            value={CollegeCity}
                            onChange={(e) => setFormData({ ...formData, CollegeCity: e.target.value })}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Course:</label>
                        <input
                            required
                            type="text"
                            value={Course}
                            onChange={(e) => setFormData({ ...formData, Course: e.target.value })}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Branch:</label>
                        <input
                            required
                            type="text"
                            value={Branch}
                            onChange={(e) => setFormData({ ...formData, Branch: e.target.value })}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                </div>
                <button onClick={nextStep} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">Next</button>
            </div>
        </>
    );
};

export default PersonalInfo;

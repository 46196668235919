

const CoursesCapacity = () => {
  return (
    <div>
    <p className=" text-xl text-gray-400 font-bold py-2">
      B.Tech
    </p>
    <ul className="md:text-base text-xl text-gray-400 list-none py-2">
      <li className="flex justify-between py-1">
        <p>CSE</p>
        <p>100</p>
      </li>
      <li className="flex justify-between py-1">
        <p>CSE</p>
        <p>100</p>
      </li>
      <li className="flex justify-between py-1">
        <p>CSE</p>
        <p>100</p>
      </li>
      <li className="flex justify-between py-1">
        <p>CSE</p>
        <p>100</p>
      </li>
    </ul>
  </div>
  )
}

export default CoursesCapacity
import React from 'react'
import Logo from '../assets/image/logo.png'
import Camera from '../assets/image/Camera.png'
import Mic from '../assets/image/Mic.png'
import Setting from '../assets/image/Setting.png'
import Screen from '../assets/image/Screen.png'

function VideoConfrence() {
    return (
        <div>
            <div className="flex  bg-muted">

                <div className="w-1/6 bg-secondary text-secondary-foreground flex flex-col items-center py-4">
                    <img src={Logo} alt="Logo" className="mb-8 w-1/2" />
                    <button className="font-semibold text-primary-foreground w-20 h-10 p-2 rounded-lg mb-4" style={{ backgroundColor: '#DDDDDD' }}>React</button>
                    <button className="font-semibold text-primary-foreground w-20 h-10 p-2 rounded-lg mb-4" style={{ backgroundColor: '#DDDDDD' }}>Ask</button>
                    <div className="flex items-center mt-auto mb-4">
                        <img src="https://placehold.co/50x50" alt="User Avatar" className="rounded-full" />
                        <span className="ml-2 text-sm">Sahil Panwar<br />IT-22185</span>
                    </div>
                </div>

                <div className="flex-1 flex flex-col">

                    {/* <div className="flex items-center justify-between bg-card text-card-foreground p-4">
                        <div>
                            <h1 className="text-xl font-semibold">Choice selection Meeting</h1>
                            <p className="text-sm text-muted-foreground">03 May 2024</p>
                        </div>
                        <button className="bg-primary text-primary-foreground px-4 py-2 rounded-lg">Add Members</button>
                        <span className="bg-accent text-accent-foreground px-4 py-2 rounded-lg">15:45:30</span>
                    </div> */}

                    <div className="flex flex-1">
                        <div className="flex-1 flex flex-col bg-card text-card-foreground p-4" style={{ backgroundColor: '#F4F4FF' }}>
                            <div className="flex items-center justify-between bg-card text-card-foreground p-4">
                                <div>
                                    <h1 className="text-xl font-semibold">Choice selection Meeting</h1>
                                    <p className="text-sm text-muted-foreground">03 May 2024</p>
                                </div>
                                <span className="bg-green-500 text-white px-4 py-2 rounded-lg">15:45:30</span>
                            </div>
                            <button className="bg-white shadow text-primary-foreground px-4 py-2 rounded-lg mx-4 my-2 w-fit">+ Add Members</button>

                            <img src="https://placehold.co/600x350" alt="Meeting Video" className="rounded-lg mb-4" />
                            <div className="flex items-center space-x-4">
                                <div className="flex flex-col items-center">
                                    <img src="https://placehold.co/50x50" alt="Avi Gupta" className="rounded-full mb-2" />
                                    <span className="text-sm">Avi Gupta<br />Mentor</span>
                                </div>
                                <div className="flex flex-col items-center">
                                    <img src="https://placehold.co/50x50" alt="Shreya Singh" className="rounded-full mb-2" />
                                    <span className="text-sm">Shreya Singh<br />Moderator</span>
                                </div>
                            </div>
                            <div className="flex justify-center items-center space-x-4 mt-4">
                                <button className="bg-primary text-primary-foreground p-2 rounded-lg">
                                    <img style={{width: '40px'}} src={Camera} alt="Camera" />
                                </button>
                                <button className="bg-primary text-primary-foreground p-2 rounded-lg">
                                    <img style={{width: '40px'}} src={Mic} alt="Microphone" />
                                </button>
                                <button className="bg-primary text-primary-foreground p-2 rounded-lg">
                                    <img style={{width: '40px'}} src={Screen} alt="Screen Share" />
                                </button>
                                <button className="bg-primary text-primary-foreground p-2 rounded-lg">
                                    <img style={{width: '40px'}} src={Setting} alt="Settings" />
                                </button>
                                <button className="bg-red-500 text-white px-4 py-3 rounded-lg h-fit">Leave meeting</button>
                            </div>
                        </div>


                        <div className="w-1/3 bg-card text-card-foreground p-4 h-screen">
                            <div className="flex justify-evenly mb-4 p-1 rounded-2xl" style={{ backgroundColor: '#E2E9F0' }}>
                                <button className="bg-white font-bold text-green-500 px-10 py-1 rounded-lg">Doubt Solving</button>
                                <button className="bg-secondary font-bold text-secondary-foreground px-10 py-1 rounded-lg">Chat (4)</button>
                            </div>
                            <div className="flex flex-col p-4 bg-muted" style={{height:'90vh'}}>
                                <div className="mb-4">
                                    <h1 className="text-2xl font-bold text-foreground">Doubt Solving</h1>
                                    <p className="text-muted-foreground">Helps to maintain session interactive</p>
                                </div>
                                <div className="flex-1 overflow-y-auto">
                                    <div className="flex items-start mb-4">
                                        <img className="w-10 h-10 rounded-full mr-3" src="https://placehold.co/40x40" alt="User avatar" />
                                        <div>
                                            <p className="text-sm font-semibold text-foreground">Sarthak Gupta</p>
                                            <div className="px-3 py-2 mt-1 text-center w-fit border border-primary rounded-xl text-foreground rounded-tl-none" style={{backgroundColor:'#DDD'}}>Which is better option CS v/s IT</div>
                                        </div>
                                    </div>
                                    <div className="flex items-start justify-end mb-4">
                                        <div>
                                            <p className="text-sm font-semibold text-foreground text-right">Sarthak Gupta</p>
                                            <div className="px-3 py-2 mt-1 text-center w-fit bg-muted-foreground rounded-xl text-background rounded-tr-none" style={{backgroundColor:'#DDD'}}>Reason</div>
                                        </div>
                                        <img className="w-10 h-10 rounded-full ml-3" src="https://placehold.co/40x40" alt="User avatar" />
                                    </div>
                                </div> 
                                <div className="flex items-center p-2 bg-card rounded-lg">
                                    <button className="p-2">
                                        <img undefinedhidden="true" alt="attachment" src="https://openui.fly.dev/openui/24x24.svg?text=🔗" />
                                    </button>
                                    <input type="text" placeholder="Type your message" className="flex-1 px-4 py-2 bg-input rounded-lg text-foreground" style={{backgroundColor:'#DDD'}}/>
                                    <button className="p-2 bg-destructive rounded-full">
                                        <img className='bg-gray-300 p-2 rounded-xl' undefinedhidden="true" alt="send" src="https://openui.fly.dev/openui/24x24.svg?text=>" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoConfrence
import React from 'react';
import { ArrowRight } from 'lucide-react';
import ground from '../../assets/image/ground.png';

const DisplayImages = () => {
  return (
    <div className="flex flex-row  w-full max-w-[700px] md:max-w-full h-[190px]  overflow-hidden">
      <div className="w-1/2 md:w-[525px] pr-2">
        <img src={ground} alt="ground" className="w-full h-full object-cover rounded-2xl" />
      </div>
      <div className="w-1/2 md:w-[175px] relative">
        <div className="relative h-full md:w-[175px] rounded-2xl overflow-hidden">
          <img
            src={ground}
            alt="ground"
            className="object-cover h-full w-full filter blur-3xl"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="absolute inset-0 flex items-center p-3 text-white text-sm  font-semibold">
          <p>View all images</p>
          <ArrowRight size={20} className="ml-2 md:ml-4" />
        </div>
      </div>
    </div>
  );
};

export default DisplayImages;
import SocketIoClient from 'socket.io-client';
import { createContext, useEffect, useReducer, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as UUIDv4 } from 'uuid';
import Peer from 'peerjs'
import {peerReducer} from '../Reducers/peerReducers';
import { addPeerAction } from '../Actions/PeerActions';

const ws_server = 'http://localhost:8000';
const SocketContext = createContext(null);
const socket = SocketIoClient(ws_server, {
    withCredentials: false,
    transports: ["polling", "websocket"]
});

const SocketProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [stream, setStream] = useState();
    const [peersOnCall, dispatch] = useReducer(peerReducer, {});

    const fetchParticipantList = useCallback(({roomId, participants}) => {
        console.log("Fetched room participants", roomId, participants);
    }, []);

    const fetchUserFeed = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setStream(stream);
        } catch (error) {
            console.error("Error accessing media devices:", error);
        }
    };

    useEffect(() => {
        const userId = UUIDv4();
        const newPeer = new Peer(userId, {
            host: "localhost",
            port: 9000,
            path: "/myapp"
        });
        setUser(newPeer);
       
        fetchUserFeed();
        const enterRoom = (roomId) => {
            navigate(`/room/${roomId}`);

        };

        socket.on('room-created', enterRoom);
        socket.on("get-users", fetchParticipantList);

        return () => {
            socket.off('room-created', enterRoom);
            socket.off("get-users", fetchParticipantList);
        };
    }, [navigate, fetchParticipantList]);

    useEffect(() => {
        if (!user || !stream) return;

        const handleUserJoined = ({userId}) => {
            const call = user.call(userId, stream);
            console.log("Calling the new peer", userId);
            call.on("stream", (peerStream) => {
                dispatch(addPeerAction({ userId, stream: peerStream }));
            });
            
        };

        const handleIncomingCall = (call) => {
            console.log("receiving a call");
            call.answer(stream);
            call.on("stream", (peerStream) => {
                dispatch(addPeerAction({ userId: call.peer, stream: peerStream }));
            });
        };

        socket.on("user-joined", handleUserJoined);
        user.on("call", handleIncomingCall);

        socket.emit("ready");

        return () => {
            socket.off("user-joined", handleUserJoined);
            user.off("call", handleIncomingCall);
        };
    }, [user, stream]);
    console.log("Socket Provider", peersOnCall);

    return (
        <SocketContext.Provider value={{socket, user, stream, peersOnCall}}>
            {children}
        </SocketContext.Provider>
    );
};

export { SocketContext, SocketProvider };

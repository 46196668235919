// src/components/ContactInfo.js
import React from 'react';

const ContactInfo = ({ formData, setFormData, prevStep, nextStep }) => {
    const { email, phone } = formData;

    return (
        <div className="w-full  rounded">
            <div style={{ height: '30rem' }}>
                <h2 className="text-2xl mb-4">Review Information</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">Email:</label>
                    <input
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Phone:</label>
                    <input
                        required
                        type="tel"
                        value={phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>
            <div className="flex justify-between">
                <button onClick={prevStep} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700">Back</button>
                <button onClick={nextStep} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">Submit</button>
            </div>
        </div>
    );
};

export default ContactInfo;

// src/components/MultiPageForm.js
import React, { useState } from 'react';
import PersonalInfo from './PersonalInfo';
import ContactInfo from './ContactInfo';
import Review from './Review';

const MultiPageForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        Name: '',
        CollegeName: '',
        CollegeCity: '',
        Course: '',
        Branch: '',
        Best: '',
        Worst: '',
        Skill: '',
    });

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
    const submitForm = () => {
        console.log('Form submitted:', formData);
        // Add form submission logic here
    };

    return (
        <div className="flex">
            {step === 1 && <PersonalInfo formData={formData} setFormData={setFormData} nextStep={nextStep} />}
            {step === 2 && <ContactInfo formData={formData} setFormData={setFormData} prevStep={prevStep} nextStep={nextStep} />}
            {step === 3 && <Review formData={formData} prevStep={prevStep} submitForm={submitForm} />}
        </div>
    );
};

export default MultiPageForm;

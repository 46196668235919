import React from 'react'

function CollegePredator() {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-zinc-100 py-6">
            <h1 className="text-2xl font-bold mb-4">College predictor for AKTU !</h1>
            <div className='flex flex-wrap'>
                <div>
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                        <form className="space-y-4">
                            <div>
                                <label htmlFor="full-name" className="block text-sm font-medium text-zinc-700">Full Name</label>
                                <input type="text" id="full-name" placeholder="Enter your name" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent" />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-zinc-700">Email</label>
                                <input type="email" id="email" placeholder="Enter your mail" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent" />
                            </div>
                            <div>
                                <label htmlFor="whatsapp-number" className="block text-sm font-medium text-zinc-700">Whatsapp Number</label>
                                <input type="text" id="whatsapp-number" placeholder="Enter your whatsapp number" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent" />
                            </div>
                            <div>
                                <label htmlFor="rank" className="block text-sm font-medium text-zinc-700">Rank</label>
                                <input type="text" id="rank" placeholder="Enter CRL Rank Only" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent" />
                            </div>
                            <div>
                                <label htmlFor="category" className="block text-sm font-medium text-zinc-700">Category</label>
                                <select id="category" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="branch" className="block text-sm font-medium text-zinc-700">Branch</label>
                                <select id="branch" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="quota" className="block text-sm font-medium text-zinc-700">Quota</label>
                                <select id="quota" className="mt-1 block w-full p-2 border border-zinc-300 rounded-md bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div>
                                <button type="submit" className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">Predict College</button>
                            </div>
                        </form>
                    </div>
                    <div className="my-6 mx-4 text-sm text-zinc-600">
                        <p>All Rounds Opening and Closing Ranks For AKTU / UPTAC / UPTU</p>
                        <p>If you belong OTHER STATE (except Uttar Pradesh) Select your category "OPEN"</p>
                        <p>Abbreviations :</p>
                        <ul className="list-disc list-inside">
                            <li>PH = Physically Handicapped</li>
                            <li>FF = Freedom Fighter</li>
                            <li>AF = Armed Forces</li>
                            <li>TF = Tuition Fees waiver</li>
                        </ul>
                    </div>
                </div>
                <div className="p-4 bg-white">
                    <p className="text-xl mb-4 text-center">
                        As per your Rank & Category you are eligible for admission in the following colleges.
                    </p>
                    <div className="bg-card p-4 rounded-lg shadow">
                        <div className="flex flex-wrap justify-between items-center mb-4">
                            <div className="bg-muted-foreground text-card-foreground py-2 px-4 rounded-lg">
                                123 Results found
                            </div>
                            <div className="flex items-center space-x-2">
                                <input type="text" placeholder="Search" className="border border-input rounded-lg px-4 py-2" />
                                <button className="bg-green-500 text-white py-2 px-4 rounded-lg">Print</button>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="bg-card p-4 rounded-lg shadow">
                                <div className="flex justify-between items-center mb-2">
                                    <a href="#" className="text-green-500 font-semibold">Institute of Engineering & Technology, Lucknow</a>
                                    <div className="flex flex-wrap justify-center items-center space-x-4">
                                        <span className="text-muted-foreground">Quota</span>
                                        <span className="text-green-500">All India</span>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-muted-foreground">Computer Science & Engineering</span>
                                    <div className="flex flex-wrap justify-center items-center space-x-4">
                                        <span className="text-muted-foreground">Seat</span>
                                        <span className="text-green-500">OPEN</span>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-card p-4 rounded-lg shadow">
                                <div className="flex justify-between items-center mb-2">
                                    <a href="#" className="text-green-500 font-semibold">Institute of Engineering & Technology, Lucknow</a>
                                    <div className="flex flex-wrap justify-center items-center space-x-4">
                                        <span className="text-muted-foreground">Quota</span>
                                        <span className="text-green-500">All India</span>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-muted-foreground">Computer Science & Engineering</span>
                                    <div className="flex flex-wrap justify-center items-center space-x-4">
                                        <span className="text-muted-foreground">Seat</span>
                                        <span className="text-green-500">OPEN</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex justify-center mt-4">
                            <button className=" text-green-500 border-2 border-green-500 py-2 px-4 rounded-lg">
                                Show More
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollegePredator

import React from "react";
import TextGreen from "../components/home/TextGreen";
import profile from "../assets/image/profile.png";
import oLetter from "../assets/image/oLetter.png";
import { FaStar } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { TiPinOutline, TiMessages } from "react-icons/ti";
import gettingStarted from "../assets/video/gettingStarted.mp4";
import { LoremIpsum } from "react-lorem-ipsum";
import CollegeCard from "../components/home/CollegeCard";
import InquiryForm from "../components/home/InquiryFoem";
import SocialCard from "../components/home/SocialCard";
import arrow from "../assets/image/arrow.png";
import insta from '../assets/image/insta.png'
import { Counter } from "../components/home/Counter";
import instagram from '../assets/image/insta.png'
import Linkedin from "../assets/image/linkedin.png";
import what from "../assets/image/what.png";
import Quora from "../assets/image/quora.png";
import yt from "../assets/image/yt.png";
import ABES from '../assets/image/abes.png'
import Galgotia from '../assets/image/GALGOTIA.jpeg.jpg'
import GLBajaj from '../assets/image/GLBAJAJ.webp'
import KIET from '../assets/image/KIET.jpg'
import NIET from '../assets/image/NIETGreaterNoida.webp'
import JSSNoida from '../assets/image/JSSNOIDA.jpg'
import KNIT from '../assets/image/KNIT.webp'
import IET from '../assets/image/IETLucknow.jpg'
import Biet from '../assets/image/BietJhansi.jpg'
import AKGEC from '../assets/image/Akgec.jpg'
import IITDelhi from '../assets/image/IITD.png'
import NITRourkela from '../assets/image/NITR.png'
import IITRoorkee from '../assets/image/iitroorkee.jpeg.jpg'
import IITHyderabad from '../assets/image/IITH.png'
import IITKGP from '../assets/image/IIT-KGP.jpeg.jpg'
import Jadavpur from '../assets/image/JadavpurUniversity.jpeg.jpg'
import IITMadras from '../assets/image/IITMadras.jpeg.jpg'
import IITGuwahati from '../assets/image/IITGuwahati.jpeg.jpg'
import IITKanpur from '../assets/image/IITKanpur.jpeg.jpg'
import IITBombay from '../assets/image/IITB.jpeg.jpg'

const Home = () => {
  return (
    <div>
      <div>{/* <Navbar/> */}</div>
      <div className="bg-gray-200 min-h-screen px-4 sm:px-8">
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 flex-col mt-6 ml-2 sm:ml-6 px-2 py-2 font-poppins">
            <p className="text-4xl sm:text-6xl md:text-5xl lg:text-8xl text-bold py-2 font-extrabold">
              College
            </p>
            <p className="text-4xl sm:text-6xl md:text-5xl lg:text-8xl text-bold py-2 font-extrabold flex flex-row items-center">
              Admissi
              <img
                className="w-10 h-10 sm:w-16 sm:h-16 md:w-20 md:h-20 object-cover rounded-full mt-1 sm:mt-2 md:mt-3"
                src={oLetter}
                alt="profile"
              />
              ns
            </p>
            <div className="flex flex-row text-4xl sm:text-6xl md:text-5xl lg:text-8xl">
              <p>at</p>
              <div className="font-ibm">
                <TextGreen text="ease" />
              </div>
            </div>
          </div>
          <div className='flex flex-1 flex-row mt-8 my-2 relative'>
            <div className='flex  flex-col mt-12  relative z-10 '>
              <div className="bg-white text-black text-bold p-3 rounded-3xl border border-gray-300 shadow-md px-5 max-w-sm mx-8 mt-4 font-poppins text-lg">
                Thankyou so much KYC!!
              </div>
              <div className='px-4 py-1 flex flex-row mx-8 '>
                <p className='text-cm px-1'>Sameer Singh</p>
                <div className='gap-1 flex flex-row text-sm mt-1'>
                  <FaStar color='green' />
                  <FaStar color='green' />
                  <FaStar color='green' />
                  <FaStar color='green' />
                </div>
              </div>
            </div>
            <div className='flex item-left justify-between absolute top-0 left-60'>
              <img className="w-40 h-40 md:w-70 md:h-70 lg:w-80 lg:h-80 object-cover rounded-full  p-1"
                src={profile} alt="profile"
              />
            </div>
          </div>
        </div>

        {/* Part 2 */}
        <div className="flex flex-col md:flex-row mt-12 md:mt-[50px] ml-2 sm:ml-8">
          <div className="flex flex-col flex-1 font-poppins text-base sm:text-lg md:text-sm lg:text-xl font-medium mt-5">
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Connect "Directly" with your future seniors.</span>
            </div>
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Get The Real Placement Insights</span>
            </div>
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Get Real Time Day to Day College Insights</span>
            </div>
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Clear your smallest doubts</span>
            </div>
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Get clarity on your college choices</span>
            </div>
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Get Personalized Choice Filling</span>
            </div>
            <div className="flex flex-row mt-2">
              <FaCheckCircle className="mr-2 mt-1 text-green-500" />
              <span>Get the best college at your rank</span>
            </div>
          </div>
          <div className="flex flex-1 justify-center mt-8 md:mt-0">
            <img
              src={arrow}
              alt="arrow"
              className="w-24 sm:w-32 md:w-auto mt-5"
            />
          </div>
          <Link
            className="flex flex-1 justify-center items-center mt-8 md:mt-0 md:mr-5"
            to={"/getstarted"}
          >
            <div className="bg-green-500 align-middle text-white text-lg sm:text-xl px-4 sm:px-6 py-3 sm:py-4 rounded-full font-poppins">
              <p>Get Started</p>
            </div>
          </Link>
        </div>

        {/* Part 3 */}
        <Counter />
        {/* Part 4 */}
        <div className="flex lg:flex-row mt-12 lg:mt-[100px] bg-zinc-800 rounded-[6rem] max-sm:rounded-xl mb-5 px-3 gap-2 py-10 justify-around mx-auto flex-wrap">
          <div className="space-y-5">
            <h2 className="text-4xl md:text-6xl font-bold text-primary" style={{ color: '#22C55E' }}>Seniors Connect</h2>
            <ul className="list-disc text-lg md:text-xl list-inside space-y-2 text-zinc-200">
              <li>Connect 1-On-1 with seniors of preferred college</li>
              <li>Get Real time insight of college</li>
              <li>Get clarity of college options</li>
              <li>Get the best college at your rank</li>
            </ul>
            <div className="flex justify-center">
              <Link to='/connect' className=" text-green-500 border-green-500 border-2 rounded-lg px-4 py-2 hover:bg-secondary/80">Get started</Link>
            </div>
          </div>
          <div className="space-y-5">
            <h2 className="text-4xl md:text-6xl font-bold text-primary" style={{ color: '#22C55E' }}>Get Your College</h2>
            <ul className="list-disc text-lg md:text-xl list-inside space-y-2 text-zinc-200">
              <li>Connect 1-On-1 with Preferred Seniors</li>
              <li>Get Personalized Counsellor</li>
              <li>Personalized Support Throughout the Process</li>
              <li>Get the help in choice filling</li>
              <li>Get 24/7 doubt support</li>
              <li>Get the best college for yourself</li>
            </ul>
            <div className="flex justify-center">
              <Link to='/college' className=" text-green-500 border-green-500 border-2 rounded-lg px-4 py-2 hover:bg-secondary/80">Enroll now</Link>
            </div>
          </div>
          {/* <div className="flex flex-1 justify-end mt-0">
            <div className="w-auto">
              <video className="w-full h-full rounded-xl" muted loop controls>
                <source src={gettingStarted} type="video/mp4" />
              </video>
            </div>
          </div> */}
        </div>

        {/* Part 5 - Top Engineering Colleges in India*/}
        <div className="flex flex-col mt-16 sm:mt-28 ml-2 sm:ml-4 mb-10">
          <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4 p-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold font-poppins text-center sm:text-left">
              Top Engineering Colleges in <span className="text-green-500"> INDIA</span>
              {/* <TextGreen text="INDIA" /> */}
            </h1>
            <Link
              to="/colleges"
              className="text-base sm:text-lg font-semibold rounded-full border border-black py-2 px-4 text-gray-500 hover:text-black transition-colors duration-300 whitespace-nowrap"
            >
              View All
            </Link>
          </div>

          <div className="flex flex-nowrap flex-row  overflow-hidden">
            <div
              className="flex flex-row mt-8 animate-slide">
              <CollegeCard link='https://home.iitd.ac.in/' loc="Delhi" img={IITDelhi} name="IIT Delhi" />
              <CollegeCard link='https://www.nitrkl.ac.in/' loc="Odisha" img={NITRourkela} name="NIT Rourkela" />
              <CollegeCard link='https://www.iitr.ac.in/' loc="Uttarakhand" img={IITRoorkee} name="IIT Roorkee" />
              <CollegeCard link='https://www.iith.ac.in/' loc="Hyderabad" img={IITHyderabad} name="IIT Hyderabad" />
              <CollegeCard link='https://www.iitkgp.ac.in/' loc="West Bengal" img={IITKGP} name="IIT Kharagpur" />
              <CollegeCard link='https://jadavpuruniversity.in/' loc="Jadavpur" img={Jadavpur} name="Jadavpur University" />
              <CollegeCard link='https://www.iitm.ac.in/' loc="Chennai" img={IITMadras} name="IIT Madras" />
              <CollegeCard link='https://www.iitg.ac.in/' loc="Assam" img={IITGuwahati} name="IIT Guwahati" />
              <CollegeCard link='https://www.iitk.ac.in/' loc="Kanpur" img={IITKanpur} name="IIT Kanpur" />
              <CollegeCard link='https://www.iitb.ac.in/' loc="Bombay" img={IITBombay} name="IIT Bombay" />
            </div>

          </div>
        </div>

        {/* Part 6 - Top Engineering Colleges in UP*/}
        <div className="flex flex-col mt-16 sm:mt-28 ml-2 sm:ml-4 mb-10">
          <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4 p-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold font-poppins text-center sm:text-left">
              Top Engineering Colleges of <span className="text-green-500"> AKTU </span>
              {/* <TextGreen text="UP" /> */}
            </h1>
            <Link
              to="/colleges"
              className="text-base sm:text-lg font-semibold rounded-full border border-black py-2 px-4 text-gray-500 hover:text-black transition-colors duration-300 whitespace-nowrap"
            >
              View All
            </Link>
          </div>
          <div className="flex flex-nowrap flex-row overflow-hidden">
            <div
              className="flex flex-row mt-8 animate-slide ">
              <CollegeCard link='https://www.galgotiasuniversity.edu.in/' loc="Noida" img={Galgotia} name="Galgotia" />
              <CollegeCard link='https://www.glbitm.org/' loc="Noida" img={GLBajaj} name="GL Bajaj" />
              <CollegeCard link='https://www.kiet.edu/' loc="Noida" img={KIET} name="KIET" />
              <CollegeCard link='https://www.niet.co.in/' loc="Noida" img={NIET} name="NIET" />
              <CollegeCard link='https://jssaten.ac.in/' loc="Noida" img={JSSNoida} name="JSS Noida" />
              <CollegeCard link='https://knit.ac.in/' loc="Sultanpur" img={KNIT} name="KNIT Sultanpur" />
              <CollegeCard link='https://abes.ac.in/' loc="Ghaziabad" img={ABES} name="ABES Engineering College" />
              <CollegeCard link='https://www.ietlucknow.ac.in/' loc="Lucknow" img={IET} name="IET Lucknow" />
              <CollegeCard link='http://bietjhs.ac.in/' loc="Jhansi" img={Biet} name="Biet Jhansi" />
              <CollegeCard link='https://www.akgec.ac.in/' loc="Ghaziabad" img={AKGEC} name="AKGEC" />
            </div>

          </div>
        </div>

        {/* Part 7 - Latest Updates*/}
        <div className="flex flex-col mt-16 sm:mt-28 ml-2 sm:ml-4 mb-10 mr-2 sm:mr-5">
          <div className="flex flex-row text-3xl sm:text-4xl md:text-5xl">
            <p className="font-poppins mr-2 font-bold">Latest Updates</p>
            <TiPinOutline color="gray" />
          </div>
          <div className="mt-8 bg-white p-4 sm:p-6 my-6">
            <div className="m-2 sm:m-4 pb-8 border-b-2 border-gray-300 flex flex-col">
              <p className="text-lg sm:text-4xl font-bold text-green-500 mb-2">
                Counselling and Choice Filling
              </p>
              <ul className="list-disc">
                <li> <a href="https://admissions.nic.in/uptac/Applicant/Root/Home.aspx?enc=yVQCIiq12npg+pcvNJRdcww8ijHE0M3JICo+UehfedimJjvXET+LsPwN9AEcJEnE" target="_blank" className="text-lg sm:text-xl  mb-2 hover:text-green-500">
                  Candidate Login for B.Tech. Counselling 2024
                </a></li>
                <li><a href="https://cdnbbsr.s3waas.gov.in/s32d969e2cee8cfa07ce7ca0bb13c7a36d/uploads/2024/07/2024071828.pdf" className="text-lg sm:text-xl mb-2  hover:text-green-500">
                  Tentative Schedule – B Tech via JEE (Mains), MBA, MCA, MCA Lateral via CUET PG
                </a></li>
                <li><a href="https://admissions.nic.in/UPTAC/applicant/report/orcrreport.aspx?boardid=130082321" className="text-lg sm:text-xl mb-2  hover:text-green-500">
                  Opening & Closing Rank UPTAC 2023 for B.Tech. Counselling Information Brochure 2024
                </a></li>
              </ul>
            </div>
            <div className="m-2 sm:m-4 flex flex-col  pb-8 border-b-2 border-gray-300">
              <p className="text-lg sm:text-4xl font-bold text-green-500 mb-2">
                Podcasts
              </p>
              <ul className="list-disc">
                <li>
                  <a href="https://www.youtube.com/watch?v=_KM0xiTTH24&t=91s" target="_blank" className="text-lg sm:text-xl  mb-2 hover:text-green-500">
                    Reality Of Startup Culture In DTU | Founder Lensroom - Ritik Tripathi
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/watch?v=Ph9dFWp_wfs" className="text-lg sm:text-xl mb-2  hover:text-green-500">
                    Branch Or College? What Matters More?🤯Journey of 7 Figure Earner Arya Soni | BioTech branch | NSUT💰
                  </a>
                </li>
              </ul>
            </div>

            <div className="m-2 sm:m-4 pb-8 border-b-2 border-gray-300">
              <p className="text-lg sm:text-4xl font-bold text-green-500 mb-2">
                IMPORTANT
              </p>
              <ul className="list-disc">
                <li className="text-lg sm:text-xl font-semibold mb-2">
                  UPTAC 2024 B Tech First Year (Excluding Bio Technology & Agriculture), MBA, MCA, MCA Lateral
                </li>
                <li className="text-lg sm:text-xl font-semibold mb-2">
                  B Arch via NATA 2024 Registration Extended till 10-Aug-2024
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center h-full">
            <div className="mt-2">
              <Link
                to="/updates"
                className="text-base sm:text-lg flex items-center font-poppins px-4 py-2 text-white bg-black rounded-full justify-center"
              >
                View All Updates
              </Link>
            </div>
          </div>
        </div>

        {/* Our Socials Section */}
        <div className="mt-20">
          <h2 className="text-3xl md:text-4xl font-bold flex justify-center items-center mb-8">
            Our Socials <TiMessages className="ml-2" />
          </h2>

          <div className="flex w-full gap-4 justify-center my-10">

            {/* <SocialCard platform={"INSTAGRAM"} image={insta} title={"KnowYourColleges on Instagram"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"} />
            <SocialCard platform={"LINKEDIN"} image={insta} title={"KnowYourColleges on Instagram"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"} />
            <SocialCard platform={"X"} image={insta} title={"KnowYourColleges on Instagram"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"} />
            <SocialCard platform={"FACEBOOK"} image={insta} title={"KnowYourColleges on Instagram"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"} /> */}

            <a target="_blank" href=""><img className="w-20 hover:scale-[1.2]" src={what} /></a>
            <a target="_blank" href="https://www.instagram.com/knowyourcolleges_kyc"><img className="w-20 hover:scale-[1.2]" src={insta} /></a>
            <a target="_blank" href=""><img className="w-20 hover:scale-[1.2]" src={Quora} /></a>
            <a target="_blank" href=""><img className="w-20 hover:scale-[1.2]" src={yt} /></a>
            <a target="_blank" href="https://www.linkedin.com/company/know-your-colleges/"><img className="w-20 hover:scale-[1.2]" src={Linkedin} /></a>
          </div>

          {/* <div className="flex justify-center mt-8">
            <Link
              to="/posts"
              className="bg-black text-white px-6 py-3 rounded-full text-lg"
            >
              View All Posts
            </Link>
          </div> */}
        </div>

        {/* Subscribe Form Section */}
        <div className="mt-20 mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-8">
            Subscribe now to get assisted and stay updated with <span className="text-green-500"> KYC</span>
            {/* <TextGreen text="KYC" /> */}
          </h2>
          <InquiryForm />
        </div>
      </div>
    </div>
  );
};

export default Home;

import { ThumbsUp, ThumbsDown } from 'lucide-react';

const Reviews = ({ name, rating, review }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-lg font-semibold">{name}</h3>
      <span className="text-sm text-gray-600">Mentor rating: {rating}/10</span>
    </div>
    <p className="text-gray-700 mb-4 text-sm md:text-2sm">{review}</p>
    <div className="flex items-center justify-between">
      <div className="flex">
        {[...Array(4)].map((_, i) => (
          <svg key={i} className="w-5 h-5 text-green-500 fill-current" viewBox="0 0 24 24">
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
          </svg>
        ))}
      </div>
      <div className="flex space-x-2">
        <button className="p-2 rounded-full bg-green-500 text-white">
          <ThumbsUp size={20} />
        </button>
        <button className="p-2 rounded-full bg-red-500 text-white">
          <ThumbsDown size={20} />
        </button>
      </div>
    </div>
  </div>
);



export default Reviews;
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux"
import { Link, useNavigate  } from 'react-router-dom';

import { setProgress } from "../slices/loadingBarSlice";
import {login } from '../services/operations/authAPI';
import bg from '../assets/image/login_bg.png';

import oLetter from '../assets/image/o_white.png';

function Login() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
      email: "",
      password: "",
    })
  
    //const [showPassword, setShowPassword] = useState(false)
  
    const { email, password } = formData
  
    const handleOnChange = (e) => {
      setFormData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }))
    }
  
    const handleOnSubmit = (e) => {
      e.preventDefault()
      dispatch(login(email, password, navigate))
    }
  

    return (
        <div className='flex flex-col md:flex-row min-h-screen'>
            <div className='relative flex-1 '>
                <img src={bg} alt='bg' className='w-full h-full object-cover' />
                <div className='absolute inset-0  flex items-center justify-center'>
                    <div className='text-white p-8 max-w-lg'>
                        <div className='flex items-start mb-8'>
                            <div className='mr-4 leading-relaxed'>
                                <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl'>Resume your college</h1>
                                <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl  flex items-center flex-wrap'>
                                    journey with <span className='text-green-500'>Know Your Colleges</span>
                                </h1>
                                
                            </div>
                            <img src={oLetter} alt='logo' className='w-16 h-16 sm:w-20 sm:h-20' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-1  flex flex-col justify-center items-center p-8 '>
                <div className='mb-10'>
                    <h1 className='text-3xl md:text-4xl font-bold mb-5'>
                        Login to your <span className='text-green-500'>KYC</span> account
                    </h1>
                </div>
                <form onSubmit={handleOnSubmit} className='w-full max-w-md'>
                    <input
                        type='email'
                        required={true}
                        placeholder='Email'
                        name="email"
                        value={email}
                        onChange={handleOnChange}
                        className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5'
                    />
                    <input
                        type='password'
                        required={true}
                        name="password"
                        placeholder='Password'
                        value={password}
                        onChange={handleOnChange}
                        className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5'
                    />
                    <button onClick={()=>{dispatch(setProgress(60))}}
                     type='submit' className='w-full bg-green-500 hover:bg-green-600 mt-5 text-white py-2 px-4 rounded-full'>
                        Login
                    </button>
                    <div className="mt-6 flex flex-col sm:flex-row justify-between items-center">
                        <Link to="/signup" className="text-black hover:text-green-500 font-medium mb-2 sm:mb-0">Forget Password</Link>
                        <Link to="/signup" className="text-black hover:text-green-500 font-medium">New User! Register Now</Link>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};

export default Login